var memberPromotionHelper = require('../components/memberPromotion');

const { disableBodyScroll, clearAllBodyScrollLocks } = require("../thirdParty/bodyScrollLock");

function chooseBonusProducts(data) {
    

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if(data){
        if (data.bonusChoiceRuleBased) {
            bonusUrl = data.showProductsUrlRuleBased;
        } else {
            bonusUrl = data.showProductsUrlListBased;
        }

        var htmlString = '<!-- Modal -->'
            + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
            + '<span class="enter-message sr-only" ></span>'
            + '<div class="modal-dialog choose-bonus-product-dialog" '
            + 'data-total-qty="' + data.maxBonusItems + '"'
            + 'data-UUID="' + data.uuid + '"'
            + 'data-pliUUID="' + data.pliUUID + '"'
            + 'data-addToCartUrl="' + data.addToCartUrl + '"'
            + 'data-pageStart="0"'
            + 'data-pageSize="' + data.pageSize + '"'
            + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
            + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
            + '<!-- Modal content-->'
            + '<div class="modal-content">'
            + '<div class="modal-header">'
            + '    <span class="">' + data.labels.selectprods + '</span>'
            + '    <button type="button" class="close pull-right" data-dismiss="modal">'
            + '        <span aria-hidden="true">&times;</span>'
            + '        <span class="sr-only"> </span>'
            + '    </button>'
            + '</div>'
            + '<div class="modal-body"></div>'
            + '<div class="modal-footer"></div>'
            + '</div>'
            + '</div>'
            + '</div>';
        $('body').append(htmlString);
        $('.modal-body').spinner().start();

        $.ajax({
            url: bonusUrl,
            method: 'GET',
            dataType: 'json',
            success: function (response) {
                var parsedHtml = parseHtml(response.renderedTemplate);
                $('#chooseBonusProductModal .modal-body').empty();
                $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
                $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
                $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
                $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
                $('#chooseBonusProductModal').modal('show');
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

function parseHtml(html) {
    if(html){
        var $html = $('<div>').append($.parseHTML(html));

        var body = $html.find('.choice-of-bonus-product');
        var footer = $html.find('.modal-footer').children();

        return { body: body, footer: footer };
    }
}

function handlePostCartAdd(response) {
    if(response){
        if(response.addedProduct){
            $('.minicart').trigger('count:update', response);
        }
        var messageType = response.error ? 'alert-danger' : 'alert-success';
        // show add to cart toast
        if (response.newBonusDiscountLineItem
            && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
            chooseBonusProducts(response.newBonusDiscountLineItem);
        } else {
            
            if(response.error){
                if ($('.add-to-cart-messages').length === 0) {
                    $('body').append('<div class="add-to-cart-messages"></div>');
                }
                $('.add-to-cart-messages').append(
                        '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
                        + response.message
                        + '</div>'
                );

                setTimeout(function () {
                    $('.add-to-basket-alert').remove();
                }, 5000);
                
            }else{
                if(response.tecSysMessage){
                    if($('.tecsysmessage').length){
                        $('.tecsysmessage').empty().html(response.tecSysMessage);
                        $('.tecsysmessage').parent().show();
                    }
                }
                //ILB-264
                if(response.addedProduct){
                    $('.powerstep').trigger('powerstep:show', response);
                }
            }
        }
    }
}

function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

function getOptions($productContainer) {
    if($productContainer){
        var options = $productContainer
            .find('.product-option')
            .map(function () {
                var $elOption = $(this).find('.options-select');
                var urlValue = $elOption.val();
                var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                    .data('value-id');
                return {
                    optionId: $(this).data('option-id'),
                    selectedValueId: selectedValueId
                };
            }).toArray();

        return JSON.stringify(options);
    }
}

function getPidValue($el) {
    if($el){
        var pid;

        if($el.hasClass('recomendation')){
            pid = $el.data('pid');
        } else if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
            pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
        } else if ($('.product-set-detail').length || $('.product-set').length) {
            pid = $($el).closest('.product-detail').find('.product-id').text();
        } else {
            pid = $('.product-detail:not(".bundle-item")').data('pid');
        }

        return pid;
    }
}

function addToCart() {
    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;
        var plid;

        $('body').trigger('product:beforeAddToCart', this);

        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            $('.product-detail').each(function () {
                if (!$(this).hasClass('product-set-detail')) {
                    setPids.push({
                        pid: $(this).find('.product-id').text(),
                        qty: $(this).find('.quantity-select').val(),
                        options: getOptions($(this))
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
        }

        pid = getPidValue($(this));

        var hashParams = {};
        // Loop through hash parameter, and creastes a key / value property for each.
        window.location.hash.substr(1).replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
            function ($0, $1, $2, $3) {
                hashParams[$1] = $3;
            }
        );

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
        }

        addToCartUrl = getAddToCartUrl();

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: getChildProducts(),
            quantity: getQuantitySelected($(this)),
            plid: hashParams.list
        };

        if (!$('.bundle-item').length) {
            form.options = getOptions($productContainer);
        }

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.spinner().start();
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    handlePostCartAdd(data);
                    if (data.memberPromotionClass) {
                        memberPromotionHelper.updateMemberPromotionFlag(data.memberPromotionClass);
                    }
                    $('body').trigger('product:afterAddToCart', data);
                    $.spinner().stop();
                    if (data.addedProduct) {
                        var dataLayer = data.addedProduct;
                        for (var i = 0; i < dataLayer.length; i++) {
                            window.dataLayer.push(dataLayer[i]);
                        }
                    }
                    miniCartReportingUrl(data.reportingURL);
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
}

function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

function getQuantitySelector($el) {

    var quantitySelected;
    if($el && $el.hasClass('recomendation')){
        quantitySelected = $el.closest('.add-to-cart-actions').find('.quantity-select');
    }else if ($el && $('.set-items').length) {
        quantitySelected = $($el).closest('.product-detail').find('.quantity-select');
    } else if ($el && $('.product-bundle').length) {
        var quantitySelectedModal = $($el).closest('.modal-footer').find('.quantity-select');
        var quantitySelectedPDP = $($el).closest('.bundle-footer').find('.quantity-select');
        if (quantitySelectedModal.val() === undefined) {
            quantitySelected = quantitySelectedPDP;
        } else {
            quantitySelected = quantitySelectedModal;
        }
    } else {
        quantitySelected = $('.quantity-select');
    }
    return quantitySelected;
    
}

function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

function quantityChange(params) {
    $(document).on('click', '.quantity-up-productdetails', function (e) {
        e.preventDefault();
        
        var currentQuantity = $(this).closest('.quantity').find('.quantity-select').val();

        if(currentQuantity == Number(999)){
            return;
        }
        
        var newQuantity = Number(currentQuantity) +1;
     
        $(this).closest('.quantity').find('.quantity-select').val(Number(newQuantity));
  
    });
    
    $(document).on('click', '.quantity-down-productdetails', function (e) {
        e.preventDefault();
        
        var currentQuantity = $(this).closest('.quantity').find('.quantity-select').val();
        
        if(currentQuantity != 1){
            $(this).closest('.quantity').find('.quantity-select').val(currentQuantity -1);	
        }
    });
}

function focusChooseBonusProductModal() {
    $('body').on('shown.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'true');
        $('#chooseBonusProductModal .close').focus();
    });
}

function availability() {

    $(document).on('change', '.quantity-select', function (e) {
        e.preventDefault();

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.modal-content').find('.product-quickview');
        }

        if ($('.bundle-items', $productContainer).length === 0) {
            attributeSelect($(e.currentTarget).find('option:selected').data('url'),
                $productContainer);
        }
    });

}

function owlCarousel() {
    $(document).ready(function() {
        initOwlCarousel();
    });
}

function initOwlCarousel(){
	var sync1 = $('.product-detail .primary-images .image-slider');
    var sync2 = $('.product-detail .primary-images .thumb-slider');
    sync1.owlCarousel({
        items: 1,
        nav: true,
        dots: false,
        navText: "",
        loop: false,
        responsive: {
            0: {
                nav: false,
                dots: true
            },
            768: {
                nav: true
            }
        }
    }).on('changed.owl.carousel', syncPosition);

    sync2.on('initialized.owl.carousel', function () {
        sync2.find(".owl-item").eq(0).addClass("current");
    }).owlCarousel({
        nav: false,
        dots: false,
        margin: 20,
        responsiveBaseElement: $("#maincontent"),
        responsiveClass:true,
        responsive: {
            768: {
                items: 3
            },
            1024: {
                items: 6
            }
        },
        slideBy: 1
    });

    sync2.on("click", ".owl-item", function (e) {
        e.preventDefault();
        var number = $(this).index();
        sync1.data('owl.carousel').to(number, 300, true);
    });

    function syncPosition(el) {
        var current = el.item.index;

        sync2
            .find(".owl-item")
            .removeClass("current")
            .eq(current)
            .addClass("current");
        var start = sync2.find('.owl-item.active').first().index();
        var end = sync2.find('.owl-item.active').last().index();

        if (current > end) {
            sync2.trigger('next.owl.carousel');
        }
        if (current < start) {
            sync2.trigger('prev.owl.carousel');
        }
    }

    if($('.sticky-mobile-info').parents('.quick-view-dialog').length == 0 && !$('.sticky-mobile-info').hasClass('no-show') && !$('.sticky-mobile-info').hasClass('show-on-button')) {

         $(window).on('scroll', function() {
            if(window.innerWidth < 767) {
                var bottomOfParent = $('.product-details-container .attributes').offset().top + $('.product-details-container .attributes').outerHeight(true) - $(window).height();
                if($(window).scrollTop() > bottomOfParent) {
                    $('.spacer').remove();
                    $('.sticky-mobile-info').removeClass('fixed-bottom');
                } else {
                    if($('.spacer').length === 0) {
                        $('<div class="spacer" style="display: block; width: '+$('.sticky-mobile-info').width()+'px; height: '+$('.sticky-mobile-info').outerHeight(true)+'px;"></div>').insertAfter($('.sticky-mobile-info'));
                        $('.sticky-mobile-info').addClass('fixed-bottom');
                    }
                }
            } else {
                $('.spacer').remove();
                $('.sticky-mobile-info').removeClass('fixed-bottom');
            }
        });
        if(window.innerWidth < 767) {
            $(window).trigger('scroll');
        }
    }else if($('.sticky-mobile-info').parents('.quick-view-dialog').length == 0 && $('.sticky-mobile-info').hasClass('show-on-button')){
        var bottomOfParentLoad = $('.product-details-container .attributes').offset().top + $('.product-details-container .attributes').outerHeight(true) - $(window).height();
        if($(window).scrollTop() > bottomOfParentLoad && window.innerWidth < 767) {
            $('<div class="spacer" style="display: block; width: '+$('.sticky-mobile-info').width()+'px; height: '+$('.sticky-mobile-info').outerHeight(true)+'px;"></div>').insertAfter($('.sticky-mobile-info'));
            $('.sticky-mobile-info').addClass('fixed-bottom');
        }
        $(window).on('scroll', function() {
           if(window.innerWidth < 767) {
            var bottomOfParent = $('.product-details-container .attributes').offset().top + $('.product-details-container .attributes').outerHeight(true);
               if($(window).scrollTop() < bottomOfParent) {
                   $('.spacer').remove();
                   $('.sticky-mobile-info').removeClass('fixed-bottom');
               } else {
                   if($('.spacer').length === 0) {
                       $('<div class="spacer" style="display: block; width: '+$('.sticky-mobile-info').width()+'px; height: '+$('.sticky-mobile-info').outerHeight(true)+'px;"></div>').insertAfter($('.sticky-mobile-info'));
                       $('.sticky-mobile-info').addClass('fixed-bottom');
                   }
               }
           } else {
               $('.spacer').remove();
               $('.sticky-mobile-info').removeClass('fixed-bottom');
           }
       });
    }

}

function updateOptions(optionsHtml, $productContainer) {
	// Update options
    if($productContainer){
        $productContainer.find('.product-options').empty().html(optionsHtml);
    }
}

function updateUnavailable(product, $productContainer){
    if(product){
        if(product.readyToOrder && !product.available && product.customAttributes.discontinued && !$productContainer.find('#quantity-gift-registry-selected').length){
            $productContainer.find(".product-saveforlater-components").first().attr("hidden", "");
            $productContainer.find(".quantity").addClass("disabled");
        }else if(product.readyToOrder && !product.available && !product.availability.availableForCC && !$productContainer.find('#quantity-gift-registry-selected').length){
            $productContainer.find(".product-saveforlater-components").first().removeAttr("hidden");
            $productContainer.find(".quantity").addClass("disabled");
        }else{
            $productContainer.find(".product-saveforlater-components").first().removeAttr("hidden");
            $productContainer.find(".quantity").removeClass("disabled");
        }
    }
}

function attributeSelect(selectedValueUrl, $productContainer, isModalContext) {
    if (selectedValueUrl) {
        $.spinner().start();
    	if(!isModalContext){
    		$('body').trigger('product:beforeAttributeSelect',
    	            { url: selectedValueUrl, container: $productContainer });
    	}

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
            	if(isModalContext){
            		$('#storesProductInventoryModal').find('.modal-body').replaceWith($(data).find('.modal-body'));
                    if($('#storesProductInventoryModal .attribute-dropdown .dropdown-item').hasClass('selected')) {
                        $('#storesProductInventoryModal .attribute-dropdown .dropdown-toggle').html(
                            $('#storesProductInventoryModal .attribute-dropdown .dropdown-item.selected').html()
                        );
                    }
            	}else if($('#confirmProductModal').length){
                    handleGiftPortalVariantResponse(data, $productContainer)
                    $('#confirmProductModal').find('form').attr('action', data.giftPortalUrl);
                }else{
            		handleVariantResponse(data, $productContainer);
                    updateOptions(data.product.optionsHtml, $productContainer);
                    updateUnavailable(data.product, $productContainer);
                    $('body').trigger('product:afterAttributeSelect',
                        { data: data, container: $productContainer });
                    if($('.attribute-dropdown .dropdown-item').hasClass('selected')) {
                        $('.attribute-dropdown .dropdown-toggle').html($('.attribute-dropdown .dropdown-item.selected').html());
                    }
            	}
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

function handleVariantResponse(response, $productContainer) {
    if(response){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(response.dataLayer);
        var isChoiceOfBonusProducts =
            $productContainer.parents('.choose-bonus-product-dialog').length > 0;
        var isVaraint;
        if (response.product.variationAttributes) {
            updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
            isVaraint = response.product.productType === 'variant';
            if (isChoiceOfBonusProducts && isVaraint) {
                $productContainer.parent('.bonus-product-item')
                    .data('pid', response.product.id);

                $productContainer.parent('.bonus-product-item')
                    .data('ready-to-order', response.product.readyToOrder);
            }

        }
        // Update primary images
        if(response.product.images.template){
            $('.primary-images').replaceWith(response.product.images.template);
            initOwlCarousel();
        }

        //Update name
        if(response.product.productName){
            $('.product-name').html(response.product.productName);
        }
        
        // Update pricing
        if (!isChoiceOfBonusProducts) {
            var $priceSelector = $('.prices', $productContainer).length
                ? $('.prices', $productContainer)
                : $('.member-prices', $productContainer).length 
                ? $('.member-prices', $productContainer)
                : $('.prices .price');
            $priceSelector.replaceWith(response.product.price.html);
        }

        // Update promotions
        $productContainer.find('.promotion-wrapper').replaceWith(response.product.promotionsHtml);
        // $productContainer.find('.promotion-splash').replaceWith(response.product.promotionsHtml);
        if(response.product.customAttributes.campaignEndDateMsg){
            console.log(response.product.customAttributes.campaignEndDateMsg)
            $productContainer.find('.campaign-end-span').empty().text(response.product.customAttributes.campaignEndDateMsg)
        }
        $productContainer.find('.campaign-end').attr('hidden', !response.product.customAttributes.campaignEndDateMsg)
    
        updateAvailability(response, $productContainer);

        // Update Click and collect
        if((response.product.ccEnabled && !$productContainer.find('.product-details-container').hasClass('cc-enabled')) || 
            ($productContainer.find('.product-details-container').hasClass('cc-enabled') && !response.product.ccEnabled)){
            $productContainer.find('.product-details-container').toggleClass('cc-enabled')
        }

        // Update Action Buttons - DON'T UPDATE INCASE OF DETAIL-PANEL (GIFT REGRISTRY)
        if(response.productButtons && $('.detail-panel','.product-details-container').length !== 1){
            var $buttonsSelector = $('.add-to-cart-actions', $productContainer).length ? $('.add-to-cart-actions', $productContainer) : $('.add-to-cart-actions');
            if($buttonsSelector.length > 1){
                $($buttonsSelector[0]).replaceWith(response.productButtons);
            }else{
                $buttonsSelector.replaceWith(response.productButtons);
            }
        }

        // $productContainer.find('.click-and-collect.btn').attr('hidden', !!!response.product.ccEnabled)
        // $productContainer.find('.click-and-collect.btn').attr('disabled', !!!response.inStockInStore)
        // $productContainer.find('.click-and-collect.btn').data('instock', !!response.inStockInStore)

        // if($productContainer.find('.click-and-collect.btn') && response.findClickAndCollectStores){
        //     $productContainer.find('.click-and-collect.btn').data('findstores', response.findClickAndCollectStores)
        //     $productContainer.find('.click-and-collect.btn').data('pid', response.product.id)
        //     $productContainer.find('.click-and-collect.btn').data('carturl', response.clickAndCollectCart)
        // }

        // Update Store Inventory 
        if($productContainer.find('.store-inventory') && response.findProductStores){
            $productContainer.find('.store-inventory').data('findstores', response.findProductStores)
            $productContainer.find('.store-inventory').data('pid', response.product.id)
        }

        // Update inventory status
        if(response.product.inventoryStatus){
            $('.inventorystatus-js').replaceWith(response.product.inventoryStatus);
        }

        if (isChoiceOfBonusProducts) {
            var $selectButton = $productContainer.find('.select-bonus-product');
            $selectButton.trigger('bonusproduct:updateSelectButton', {
                product: response.product, $productContainer: $productContainer
            });
        } else {
            // Enable "Add to Cart" button if all required attributes have been selected
            $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
                product: response.product, resource: response.resources, $productContainer: $productContainer
            }).trigger('product:statusUpdate', response.product);
        }

        // Update attributes
        $productContainer.find('.main-attributes').empty()
            .html(getAttributesHtml(response.product.attributes));
        
        // Update wishlist button
        var $icon = $productContainer.find('button.add-to-wish-list i');    
        $icon.removeClass('fa-heart-o');
        $icon.removeClass('fa-heart');
        if (response.product.isOnWishlist == true) {
            $icon.addClass('fa-heart');
        } else {
            $icon.addClass('fa-heart-o');
        }
        
        //Update instore pickup link
        if($('.instore-pickup').length > 0 && response.hasOwnProperty('pickUpInStore')){
            $('.instore-pickup').attr('href', response.pickUpInStore.actionUrl);
        }
        
        //Update no1 points
        if(response.product.no1Points && response.product.readyToOrder && response.product.available){
            $('.no1-points > .points').empty().html(response.product.no1Points.points);
            $('.no1-points').removeAttr("hidden");
        }else if(!response.product.readyToOrder || !response.product.available){
            $('.no1-points').attr("hidden", "");
        }
        
        //Update product details
        if(response.product.details){
            $('.description-and-detail').replaceWith(response.product.details);
        }

        //Update selected color
        if(response.product.customAttributes.colorDisplayValue && $('.color-name').length){
            $('.color-name').text(response.product.customAttributes.colorDisplayValue);
        }
    }
}

function handleGiftPortalVariantResponse(response, $productContainer) {
    if(response){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(response.dataLayer);
        var isChoiceOfBonusProducts =
            $productContainer.parents('.choose-bonus-product-dialog').length > 0;
        var isVaraint;
        if (response.product.variationAttributes) {
            updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
            isVaraint = response.product.productType === 'variant';
            if (isChoiceOfBonusProducts && isVaraint) {
                $productContainer.parent('.bonus-product-item')
                    .data('pid', response.product.id);

                $productContainer.parent('.bonus-product-item')
                    .data('ready-to-order', response.product.readyToOrder);
            }

        }
        // Update primary images
        if(response.product.images.template){
            $('.primary-images').replaceWith(response.product.images.template);
            initOwlCarousel();
        }

        //Update name
        if(response.product.productName){
            $('.product-name').html(response.product.productName);
        }

        // Update Action Buttons - DON'T UPDATE INCASE OF DETAIL-PANEL (GIFT REGRISTRY)
       $('.select-product').data('pid', response.product.id)

        // Update attributes
        $productContainer.find('.main-attributes').empty()
            .html(getAttributesHtml(response.product.attributes));
        
        
        //Update product details
        if(response.product.details){
            $('.description-and-detail').replaceWith(response.product.details);
        }

        //Update selected color
        if(response.product.customAttributes.colorDisplayValue && $('.color-name').length){
            $('.color-name').text(response.product.customAttributes.colorDisplayValue);
        }
    }
}

function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    var customAvailabilityMessage = response.product.customAttributes.availabilityMessage;
    
    if (response.product.readyToOrder && response.product.available) {
    	availabilityValue = customAvailabilityMessage;
    }
    
    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    if(attrs){
        attrs.forEach(function (attr) {
            if (attr.swatchable) {
                processSwatchValues(attr, $productContainer, msgs);
            } else if(attr.isColorAttr || attr.isSizeAttr && !attr.swatchable){
                processNonSwatchValues(attr, $productContainer);
            }else{
                processGeneralAttributeValues(attr, $productContainer);
            }
        });
    }
}

function processSwatchValues(attr, $productContainer, msgs) {
    if(attr){
        attr.values.forEach(function (attrValue) {
            var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
                attrValue.value + '"]');
            var $swatchButton = $attrValue.parent();

            if (attrValue.selected) {
                $attrValue.addClass('selected');
                $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
            } else {
                $attrValue.removeClass('selected');
                $attrValue.siblings('.selected-assistive-text').empty();
            }

            if (attrValue.url) {
                $swatchButton.attr('data-url', attrValue.url);
            } else {
                $swatchButton.removeAttr('data-url');
            }

            // Disable if not selectable
            $attrValue.removeClass('selectable unselectable');

            $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
        });
    }
}

function processNonSwatchValues(attr, $productContainer) {
    if(attr){
        var $attr = '[data-attr="' + attr.id + '"]';
        var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
        $defaultOption.attr('value', attr.resetUrl);

        attr.values.forEach(function (attrValue) {
            var $attrValue = $productContainer
                .find($attr + ' [data-attr-value="' + attrValue.value + '"]');

            $attrValue.each(function() {
                if($(this).is('option')) {
                    $(this).attr('value', attrValue.url)
                    .removeAttr('disabled');
                } else {
                    $(this).attr('href', attrValue.url)
                    .removeAttr('disabled');
                }
            });

            if (attrValue.selected) {
                $attrValue.addClass('selected');
            } else {
                $attrValue.removeClass('selected');
            }
        // Disable if not selectable
            $attrValue.removeClass('selectable unselectable');
            $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
        });
    }
}

function processGeneralAttributeValues(attr, $productContainer){
    if(attr){
        attr.values.forEach(function (attrValue) {
            var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
                    attrValue.value + '"]');
            var $attrButton = $attrValue.parent();
            
            if (attrValue.selected) {
                $attrValue.addClass('selected');
                
            } else {
                $attrValue.removeClass('selected');
            }
            
            if (attrValue.url) {
                $attrButton.attr('data-url', attrValue.url);
            } else {
                $attrButton.removeAttr('data-url');
            }
            
            // Disable if not selectable
            $attrValue.removeClass('selectable unselectable');

            $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
        });
    }
}

function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

function lockbodyscroll(el) {
    if(el){
        console.log('body locked');
        $('body').addClass('locked');
        disableBodyScroll(el);
    }
}

function clearbodyscroll() {
    // console.log('body lock cleared');
    $('body').removeClass('locked');
    clearAllBodyScrollLocks();
}


function getStores(url) {
    if(url){
        $.spinner().start()
        $.ajax({
            url: url,
            type: 'get',
            success: function (data) {
                $('#chooseClickAndCollectStoreModal').remove();
                $('#chooseClickAndCollectStoreModal').find('.modal-content').empty().append(data);
                $(data).appendTo('body');
                $("#chooseClickAndCollectStoreModal").modal("show");
                lockbodyscroll(document.querySelector('#chooseClickAndCollectStoreModal .modal-body'));
                $("#chooseClickAndCollectStoreModal").on('hide.bs.modal', function(e) {
                    clearbodyscroll();
                });
                $.spinner().stop()
            },
            error: function (data) {
                $.spinner().stop()
            }
        })
    }
}

module.exports = {
    attributeSelect:attributeSelect,
    owlCarousel:owlCarousel,
    availability:availability,
    focusChooseBonusProductModal:focusChooseBonusProductModal,
    quantityChange:quantityChange,
    addToCart:addToCart,
    miniCartReportingUrl:miniCartReportingUrl,
    handlePostCartAdd:handlePostCartAdd,
    chooseBonusProducts:chooseBonusProducts,
    handleVariantResponse:handleVariantResponse,
    updateOptions: updateOptions,
    updateUnavailable: updateUnavailable,
    updateAvailability: updateAvailability,
    updateAttrs: updateAttrs,
    processNonSwatchValues: processNonSwatchValues,
    processSwatchValues: processSwatchValues,
    processGeneralAttributeValues: processGeneralAttributeValues,
    lockbodyscroll: lockbodyscroll,
    clearbodyscroll: clearbodyscroll,
    parseHtml: parseHtml,
    getPidValue: getPidValue,
    getQuantitySelected: getQuantitySelected,
    getStores: getStores
}